import React from "react";
import {
  EyeOutlined,
  DeleteOutlined,
  MailOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import { Table, Button, Input, Popconfirm, Space, Drawer, Col, Divider, Row, DatePicker, ConfigProvider, Select, Alert, Modal, Tooltip, AutoComplete, Checkbox } from 'antd';
import { SearchOutlined, RedoOutlined, LinkOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

import { openNotification } from "../../functions";
import Highlighter from 'react-highlight-words';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import locale from 'antd/locale/ru_RU';
import axios from "axios";
import * as xlsx from 'xlsx';
import { NavBar } from "../../components/navBar";

const { Option } = Select;

export default class Newclients extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      modal: false,
      loadusers: false,
      statuses: "",
      clientPassEdit: false,
      parsingFieldsModal: false,
      loadInput: "",
      loadInputName: "Нажмите сюда и выберите файл для загрузки",
      uploadFileJson: [
        {
          "Номер заявки": 123,
        },
      ],
      uploadFileJsonFields: [],
      uploadClientName: null,
      uploadClientSurname: null,
      uploadClientPatronymic: null,
      uploadClientMail: null,
      uploadClientWorkMail: null,
      uploadClientAdditionalMail: null,
      uploadClientPhone: null,
      uploadClientWorkPhone: null,
      uploadClientAdditionalPhone: null,
      uploadClientCity: null,
      uploadClientCity2: null,
      uploadClientBirthday: null,
      uploadClientDocDate: null,
      uploadClientDocNumber: null,
      uploadClientDocSeries: null,
      uploadClientFioDiplom: null,
      uploadClientLevelDiplom: null,
      uploadClientNumberDiplom: null,
      uploadClientSeriesDiplom: null,
      uploadClientPol: null,
      uploadClientRegistrationNumber: null,
      uploadClientSnils: null,
      uploadClientInstitution: null,
      uploadClientCategory: null,
      uploadClientStatus: null,
      uploadClientFedLink: null,
      uploadClientRegion: null,
      uploadClientNumberTransferOrder: null,
      uploadClientDateTransferOrder: null,
      uploadClientNumberWithdrawalOrder: null,
      uploadClientDateWithdrawalOrder: null,
      uploadClientPassword: null,
      uploadClientEnableGroup: false,
      uploadClientGroups: [],
      uploadClientSelectedGroup: '',
      clients: [],
      courses: [],
      groups: [],
      projects: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      newClientName: "",
      newClientSurname: "",
      newClientPatronymic: "",
      newClientMail: "",
      newClientWorkMail: "",
      newClientAdditionalMail: "",
      newClientPhone: "",
      newClientWorkPhone: "",
      newClientAdditionalPhone: "",
      newClientCity: "",
      newClientCity2: "",
      newClientRegion: "",
      newClientBirthday: "00.00.0000",
      newClientDocDate: "",
      newClientDocNumber: "",
      newClientDocSeries: "",
      newClientFioDiplom: "",
      newClientLevelDiplom: "",
      newClientNumberDiplom: "",
      newClientSeriesDiplom: "",
      newClientPol: "",
      newClientRegistrationNumber: "",
      newClientSnils: "",
      newClientInstitution: "",
      newClientCourseId: "",
      newClientGroupId: "",
      newClientProjectId: "",
      newClientPrice: "",
      newClientCategory: "",
      newClientStatus: "",
      client_window: false,
      thisNewClient: false,
      inputChanged: false,
      newClientId: "",
      newClientLogin: "",
      newClientPassword: "",
      newClientPlatformId: "",
      newClientFedLink: "",
      newClientManager: "",
      newClientNumberTransferOrder: "",
      newClientDateTransferOrder: "",
      newClientNumberWithdrawalOrder: "",
      newClientDateWithdrawalOrder: "",
      platforms: "",
      users: []
    };
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value, inputChanged: true });
  }

  clearNewClientData() {
    this.setState({
      newClientId: "",
      newClientName: "",
      newClientSurname: "",
      newClientPatronymic: "",
      newClientMail: "",
      newClientWorkMail: "",
      newClientAdditionalMail: "",
      newClientPhone: "",
      newClientWorkPhone: "",
      newClientAdditionalPhone: "",
      newClientCity: "",
      newClientCity2: "",
      newClientRegion: "",
      newClientBirthday: "",
      newClientDocDate: "",
      newClientDocNumber: "",
      newClientDocSeries: "",
      newClientFioDiplom: "",
      newClientLevelDiplom: "",
      newClientNumberDiplom: "",
      newClientSeriesDiplom: "",
      newClientPol: "",
      newClientRegistrationNumber: "",
      newClientSnils: "",
      newClientInstitution: "",
      newClientCourseId: "",
      newClientGroupId: "",
      newClientProjectId: "",
      newClientPrice: "",
      newClientCategory: "",
      newClientStatus: "",
      newClientLogin: "",
      newClientPassword: "",
      newClientPlatformId: "",
      newClientFedLink: "",
      newClientManager: "",
      newClientNumberTransferOrder: "",
      newClientDateTransferOrder: "",
      newClientNumberWithdrawalOrder: "",
      newClientDateWithdrawalOrder: "",
    })
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys[0], searchedColumn: dataIndex });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  uploadFile() {
    const reader = new FileReader();

    reader.readAsArrayBuffer(this.state.loadInput.target.files[0]);

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "array" });
      const workbookHeaders = xlsx.readFile(data, { sheetRows: 1 });
      const sheetName2 = workbookHeaders.SheetNames;
      const columnsArray = xlsx.utils.sheet_to_json(workbookHeaders.Sheets[sheetName2], { header: 1 })[0];

      const sheetName = workbook.SheetNames;
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);
      let columnHeaders = [];

      columnsArray.map((elem) => {
        switch (elem) {
          case 'Имя':
            this.setState({ uploadClientName: 'Имя' })
            break;
          case 'Фамилия':
            this.setState({ uploadClientSurname: 'Фамилия' })
            break;
          case 'Отчество':
            this.setState({ uploadClientPatronymic: 'Отчество' })
            break;
          case 'Email':
            this.setState({ uploadClientMail: 'Email' })
            break;
          case 'Дополнительный email':
            this.setState({ uploadClientWorkMail: 'Дополнительный email' })
            break;
          case 'Доп. почта':
            this.setState({ uploadClientAdditionalMail: 'Доп. почта' })
            break;
          case 'Телефон':
            this.setState({ uploadClientPhone: 'Телефон' })
            break;
          case 'Номер телефона':
            this.setState({ uploadClientPhone: 'Номер телефона' })
            break;  
          case 'Дополнительный телефон':
            this.setState({ uploadClientWorkPhone: 'Дополнительный телефон' })
            break;
          case 'Доп. телефон':
            this.setState({ uploadClientAdditionalPhone: 'Доп. телефон' })
            break;
          case 'Адрес проживания гражданина':
            this.setState({ uploadClientCity: 'Адрес проживания гражданина' })
            break;
          case 'Адрес регистрации гражданина':
            this.setState({ uploadClientCity2: 'Адрес регистрации гражданина' })
            break;
          case 'Регион':
            this.setState({ uploadClientRegion: 'Регион' })
            break;
          case 'Дата рождения гражданина':
            this.setState({ uploadClientBirthday: 'Дата рождения гражданина' })
            break;
          case 'Пол':
            this.setState({ uploadClientPol: 'Пол' })
            break;
          case 'Пол гражданина':
            this.setState({ uploadClientPol: 'Пол гражданина' })
            break;
          case 'СНИЛС':
            this.setState({ uploadClientSnils: 'СНИЛС' })
            break;
          case 'Уровень образования':
            this.setState({ uploadClientLevelDiplom: 'Уровень образования' })
            break;
          case 'Серия диплома':
            this.setState({ uploadClientSeriesDiplom: 'Серия диплома' })
            break;
          case 'Серия и номер диплома (ранее выданного)':
            this.setState({ uploadClientNumberDiplom: 'Серия и номер диплома (ранее выданного)', uploadClientSeriesDiplom: 'Серия и номер диплома (ранее выданного)' })
            break;
          case 'Серия бланка документа о квалификации':
            this.setState({ uploadClientDocSeries: 'Серия бланка документа о квалификации' })
            break;
          case 'Номер бланка документа о квалификации':
            this.setState({ uploadClientDocNumber: 'Номер бланка документа о квалификации' })
            break;
          case 'Номер диплома':
            this.setState({ uploadClientDocNumber: 'Номер диплома', uploadClientDocSeries: 'Номер диплома'})
            break;
          case 'Дата выдачи документа о квалификации':
            this.setState({ uploadClientDocDate: 'Дата выдачи документа о квалификации' })
            break;
          case 'Дата выдачи':
            this.setState({ uploadClientDocDate: 'Дата выдачи' })
            break;  
          case 'Регистрационный номер документа о квалификации':
            this.setState({ uploadClientRegistrationNumber: 'Регистрационный номер документа о квалификации' })
            break;
          case 'Категория (полное)':
            this.setState({ uploadClientCategory: 'Категория (полное)' })
            break;
          case 'Категория':
            this.setState({ uploadClientCategory: 'Категория' })
            break;
          case 'Номер приказа на зачисление':
            this.setState({ uploadClientNumberTransferOrder: 'Номер приказа на зачисление' })
            break;
          case 'Приказ на зачисление':
            this.setState({ uploadClientNumberTransferOrder: 'Приказ на зачисление' })
            break;
          case 'Номер приказа на отчисление':
            this.setState({ uploadClientNumberWithdrawalOrder: 'Номер приказа на отчисление' })
            break;
          case 'Приказ об отчислении':
            this.setState({ uploadClientNumberWithdrawalOrder: 'Приказ об отчислении' })
            break;
          case 'Дата регистрации приказа на зачисление':
            this.setState({ uploadClientDateTransferOrder: 'Дата регистрации приказа на зачисление' })
            break;
          case 'Дата зачисления':
            this.setState({ uploadClientDateTransferOrder: 'Дата зачисления' })
            break;
          case 'Дата отчисления':
            this.setState({ uploadClientDateWithdrawalOrder: 'Дата отчисления' })
            break;    
          case 'Дата регистрации приказа на отчисление':
            this.setState({ uploadClientDateWithdrawalOrder: 'Дата регистрации приказа на отчисление' })
            break;
        }
      });

      this.setState({ uploadFileJson: json, loadusers: false, parsingFieldsModal: true, uploadFileJsonFields: columnsArray });
      this.getGroups();

    };
  }

  getClientInfo(id) {
    fetch(`https://student-control.nti-center.ru/api/clients/` + id + '/new', {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            newClientBirthday: response.data[0].birthday ? response.data[0].birthday : "00.00.0000",
            newClientFioDiplom: response.data[0].fio_diplom,
            newClientSeriesDiplom: response.data[0].series_diplom,
            newClientNumberDiplom: response.data[0].number_diplom,
            newClientLevelDiplom: response.data[0].level_diplom,
            newClientDocSeries: response.data[0].doc_series,
            newClientDocNumber: response.data[0].doc_number,
            newClientDocDate: response.data[0].doc_date,
            newClientRegistrationNumber: response.data[0].registration_number,
            newClientPol: response.data[0].pol,
            newClientSnils: response.data[0].snils,
            newClientCourseId: response.data[0].client_courses,
            newClientGroupId: response.data[0].client_group,
            newClientProjectId: response.data[0].client_project,
            newClientPrice: response.data[0].price,
            newClientCategory: response.data[0].category,
            newClientInstitution: response.data[0].institution,
            newClientStatus: response.data[0].client_status,
            newClientLogin: response.data[0].client_login,
            newClientPlatformId: response.data[0].platform_id,
            newClientFedLink: response.data[0].fed_link,
            newClientManager: response.data[0].manager_id,
            newClientNumberTransferOrder: response.data[0].number_transfer_order,
            newClientDateTransferOrder: response.data[0].date_transfer_order,
            newClientNumberWithdrawalOrder: response.data[0].number_withdrawal_order,
            newClientDateWithdrawalOrder: response.data[0].date_withdrawal_order,
          });
        }
      })
  }

  closeDrawer() {
    this.setState({ client_window: false, thisNewClient: false, inputChanged: false });
  }

  getClients() {
    fetch(`https://student-control.nti-center.ru/api/clients/new`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          var arr = [];
          response.data.map((client) => {
            arr.push({
              key: client.client_id,
              client_name: client.client_name,
              client_surname: client.client_surname,
              client_patronymic: client.client_patronymic,
              client_contacts: <div>
                {client.client_mail ? <div><MailOutlined /> {client.client_mail}</div> : ""}
                {client.client_work_mail ? <div><MailOutlined /> {client.client_work_mail}</div> : ""}
                {client.client_additional_mail ? <div><MailOutlined /> {client.client_additional_mail}</div> : ""}
                {client.client_phone ? <div><PhoneOutlined /> {client.client_phone}</div> : ""}
                {client.client_work_phone ? <div><PhoneOutlined /> {client.client_work_phone}</div> : ""}
                {client.client_additional_phone ? <div><PhoneOutlined /> {client.client_additional_phone}</div> : ""}
              </div>,
              client_city: client.client_city,
              client_category: client.category,
              events: <div>
                {client.fed_link ? <a style={{ cursor: 'pointer', marginRight: '10px' }} href={client.fed_link} rel="noreferrer" target="_blank"><LinkOutlined /></a> : ""}

                <Tooltip title="Смотреть">
                  <EyeOutlined onClick={() => {
                    this.setState({ client_window: true, newClientId: client.client_id, newClientName: client.client_name, newClientSurname: client.client_surname, newClientPatronymic: client.client_patronymic, newClientMail: client.client_mail, newClientWorkMail: client.client_work_mail, newClientAdditionalMail: client.client_additional_mail, newClientCity: client.client_city, newClientCity2: client.client_city2, newClientRegion: client.client_region, newClientPhone: client.client_phone, newClientWorkPhone: client.client_work_phone, newClientAdditionalPhone: client.client_additional_phone });
                    this.getClientInfo(client.client_id);
                  }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                </Tooltip>
                <Popconfirm
                  title={client.client_state == 1 ? "Вы уверены что хотите заблокировать клиента?" : "Вы уверены что хотите разблокировать клиента?"}
                  okText="Да"
                  onConfirm={() => { this.banClient(client.client_id, client.client_state == 1 ? 0 : 1); }}
                  cancelText="Нет"
                >
                  {
                    client.client_state == 1 ?
                      <Tooltip title="Клиент активен">
                        <CheckCircleTwoTone style={{ cursor: 'pointer', marginRight: '10px' }} twoToneColor="#52c41a" />
                      </Tooltip>
                      :
                      <Tooltip title="Клиент заблокирован">
                        <CloseCircleTwoTone style={{ cursor: 'pointer', marginRight: '10px' }} twoToneColor="#eb2f96" />
                      </Tooltip>
                  }
                </Popconfirm>
                {/* <Popconfirm
                  title="Вы уверены что хотите удалить клиента?"
                  okText="Да"
                  onConfirm={() => { this.deleteClient(client.client_id); }}
                  cancelText="Нет"
                >
                  <DeleteOutlined />
                </Popconfirm> */}
              </div>
            })
          })
          this.setState({ clients: arr });
        }
      })
  }

  banClient(id, state) {
    fetch(`https://student-control.nti-center.ru/api/clients/new/ban/${id}`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        state: state
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.getClients();
        }
      });

  }

  getColumnSearchProps = (dataIndex, placeName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input ref={this.state.searchInput} placeholder={`Поиск ${placeName}`} value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{}}>Поиск</Button>
          <Button onClick={() => clearFilters && this.handleReset(clearFilters)} size="small" >Сбросить</Button>
          <Button type="link" size="small" onClick={() => { close(); }}>Закрыть</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.state.searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  patchClient() {
    fetch(`https://student-control.nti-center.ru/api/clients/new`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        "client_id": this.state.newClientId,
        "client_name": this.state.newClientName,
        "client_surname": this.state.newClientSurname,
        "client_patronymic": this.state.newClientPatronymic,
        "client_mail": this.state.newClientMail,
        "client_work_mail": this.state.newClientWorkMail,
        "client_additional_mail": this.state.newClientAdditionalMail,
        "client_phone": this.state.newClientPhone,
        "client_work_phone": this.state.newClientWorkPhone,
        "client_additional_phone": this.state.newClientAdditionalPhone,
        "client_city": this.state.newClientCity,
        "client_city2": this.state.newClientCity2,
        "client_region": this.state.newClientRegion,
        "course_id": this.state.newClientCourseId,
        "group_id": this.state.newClientGroupId,
        "project_id": this.state.newClientProjectId,
        "client_status": this.state.newClientStatus,
        "snils": this.state.newClientSnils,
        "pol": this.state.newClientPol,
        "birthday": this.state.newClientBirthday,
        "fio_diplom": this.state.newClientFioDiplom,
        "series_diplom": this.state.newClientSeriesDiplom,
        "number_diplom": this.state.newClientNumberDiplom,
        "level_diplom": this.state.newClientLevelDiplom,
        "institution": this.state.newClientInstitution,
        "doc_series": this.state.newClientDocSeries,
        "doc_number": this.state.newClientDocNumber,
        "doc_date": this.state.newClientDocDate,
        "registration_number": this.state.newClientRegistrationNumber,
        "category": this.state.newClientCategory,
        "price": this.state.newClientPrice,
        "client_login": this.state.newClientLogin,
        "platform_id": this.state.newClientPlatformId,
        "fed_link": this.state.newClientFedLink,
        "manager_id": this.state.newClientManager,
        "number_transfer_order": this.state.newClientNumberTransferOrder,
        "date_transfer_order": this.state.newClientDateTransferOrder,
        "number_withdrawal_order": this.state.newClientNumberWithdrawalOrder,
        "date_withdrawal_order": this.state.newClientDateWithdrawalOrder,
      })
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Клиент успешно изменён!', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false });
          }
          // this.closeDrawer();
          this.getClients();
          // this.getGroups();
        }
      });
  }

  getGroups() {
    fetch(`https://student-control.nti-center.ru/api/clients/groups/all`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Нет ни одной группы', response.error, 1, 4.5);
        } else {
          this.setState({ uploadClientGroups: response.data })
        }
      })
  }

  editClientPassword() {
    if (this.state.newClientPassword === "") {
      openNotification('Ошибка', "Поле нового пароля пустое", 1, 4.5);
    } else {
      fetch(`https://student-control.nti-center.ru/api/clients/ep/new`, {
        headers: new Headers({
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'CokCRM ' + localStorage.getItem('token')
        }),
        method: 'PATCH',
        cache: 'no-cache',
        body: JSON.stringify({
          "client_id": this.state.newClientId,
          "client_password": this.state.newClientPassword
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.error) {
            openNotification('Ошибка', response.error, 1, 4.5);
          } else {
            this.setState({ clientPassEdit: false, newClientPassword: "" })
            openNotification('Успех', 'Пароль клиента был изменен', 3, 4.5);
          }
        })
    }

  }

  createClient() {

    fetch(`https://student-control.nti-center.ru/api/clients/new`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        "client_name": this.state.newClientName,
        "client_surname": this.state.newClientSurname,
        "client_patronymic": this.state.newClientPatronymic,
        "client_mail": this.state.newClientMail,
        "client_work_mail": this.state.newClientWorkMail,
        "client_additional_mail": this.state.newClientAdditionalMail,
        "client_phone": this.state.newClientPhone,
        "client_work_phone": this.state.newClientWorkPhone,
        "client_additional_phone": this.state.newClientAdditionalPhone,
        "client_city": this.state.newClientCity,
        "client_city2": this.state.newClientCity2,
        "client_region": this.state.newClientRegion,
        "course_id": this.state.newClientCourseId,
        "group_id": this.state.newClientGroupId,
        "project_id": this.state.newClientProjectId,
        "client_status": this.state.newClientStatus,
        "snils": this.state.newClientSnils,
        "pol": this.state.newClientPol,
        "birthday": this.state.newClientBirthday,
        "fio_diplom": this.state.newClientFioDiplom,
        "series_diplom": this.state.newClientSeriesDiplom,
        "number_diplom": this.state.newClientNumberDiplom,
        "level_diplom": this.state.newClientLevelDiplom,
        "institution": this.state.newClientInstitution,
        "doc_series": this.state.newClientDocSeries,
        "doc_number": this.state.newClientDocNumber,
        "doc_date": this.state.newClientDocDate,
        "registration_number": this.state.newClientRegistrationNumber,
        "category": this.state.newClientCategory,
        "price": this.state.newClientPrice,
        "client_password": this.state.newClientPassword,
        "platform_id": this.state.newClientPlatformId,
        "fed_link": this.state.newClientFedLink,
        "manager_id": this.state.newClientManager,
        "number_transfer_order": this.state.newClientNumberTransferOrder,
        "date_transfer_order": this.state.newClientDateTransferOrder,
        "number_withdrawal_order": this.state.newClientNumberWithdrawalOrder,
        "date_withdrawal_order": this.state.newClientDateWithdrawalOrder
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Вы создали клиента!', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false, newClientPassword: "" });
          }
          this.getClients();
        }
      });
  }

  uploadClients() {
    fetch(`https://student-control.nti-center.ru/api/clients/file`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token'),
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        xlsxJson: this.state.uploadFileJson,
        settings: {
          name: this.state.uploadClientName,
          surname: this.state.uploadClientSurname,
          patronymic: this.state.uploadClientPatronymic,
          mail: this.state.uploadClientMail,
          workMail: this.state.uploadClientWorkMail,
          additionalMail: this.state.uploadClientAdditionalMail,
          phone: this.state.uploadClientPhone,
          workPhone: this.state.uploadClientWorkPhone,
          additionalPhone: this.state.uploadClientAdditionalPhone,
          city: this.state.uploadClientCity,
          city2: this.state.uploadClientCity2,
          birthday: this.state.uploadClientBirthday,
          docDate: this.state.uploadClientDocDate,
          docNumber: this.state.uploadClientDocNumber,
          docSeries: this.state.uploadClientDocSeries,
          fioDiplom: this.state.uploadClientFioDiplom,
          levelDiplom: this.state.uploadClientLevelDiplom,
          numberDiplom: this.state.uploadClientNumberDiplom,
          seriesDiplom: this.state.uploadClientSeriesDiplom,
          pol: this.state.uploadClientPol,
          registrationNumber: this.state.uploadClientRegistrationNumber,
          snils: this.state.uploadClientSnils,
          institution: this.state.uploadClientInstitution,
          category: this.state.uploadClientCategory,
          status: this.state.uploadClientStatus,
          fed_link: this.state.uploadClientFedLink,
          region: this.state.uploadClientRegion,
          NumberTransferOrder: this.state.uploadClientNumberTransferOrder,
          DateTransferOrder: this.state.uploadClientDateTransferOrder,
          NumberWithdrawalOrder: this.state.uploadClientNumberWithdrawalOrder,
          DateWithdrawalOrder: this.state.uploadClientDateWithdrawalOrder,
          password: this.state.uploadClientPassword,
          useGroup: this.state.uploadClientEnableGroup,
          group_id: this.state.uploadClientSelectedGroup
        }

        // {
        //   "name": "Имя",
        //   "surname": "Фамилия",
        //   "patronymic": "Отчество",
        //   "mail": "Email",
        //   "workMail": "Дополнительный email",
        //   "additionalMail": null,
        //   "phone": "Телефон",
        //   "workPhone": "Дополнительный телефон",
        //   "additionalPhone": null,
        //   "city": "Адрес проживания гражданина",
        //   "city2": "Адрес регистрации гражданина",
        //   "birthday": "Дата рождения гражданина",
        //   "docDate": "Дата выдачи документа о квалификации",
        //   "docNumber": "Номер бланка документа о квалификации",
        //   "docSeries": "Серия бланка документа о квалификации",
        //   "fioDiplom": "ФИО",
        //   "levelDiplom": "Уровень образования",
        //   "numberDiplom": "Серия и номер диплома (ранее выданного)",
        //   "seriesDiplom": "Серия и номер диплома (ранее выданного)",
        //   "pol": "Пол гражданина",
        //   "registrationNumber": "Регистрационный номер документа о квалификации",
        //   "snils": "СНИЛС",
        //   "institution": null,
        //   "category": "Категория (полное)",
        //   "status": null,
        //   "fed_link": "Ссылка на документ об образовании",
        //   "region": "Регион",
        //   "NumberTransferOrder": "Номер бланка документа о квалификации",
        //   "DateTransferOrder": "Дата регистрации приказа на зачисление",
        //   "NumberWithdrawalOrder": "Номер приказа на отчисление",
        //   "DateWithdrawalOrder": "Дата регистрации приказа на отчисление",
        //   "password": null,
        //   "useGroup": true,
        //   "group_id": 52
        // }
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Клиенты были добавлены', 3, 4.5);
        }
      })
  }

  render() {
    const columns = [
      {
        title: 'Фамилия',
        dataIndex: 'client_surname',
        key: 'client_surname',
        ...this.getColumnSearchProps('client_surname', 'по фамилии'),
      },
      {
        title: 'Имя',
        dataIndex: 'client_name',
        key: 'client_name',
        ...this.getColumnSearchProps('client_name', 'по имени'),
      },
      {
        title: 'Отчество',
        dataIndex: 'client_patronymic',
        key: 'client_patronymic',
        ...this.getColumnSearchProps('client_patronymic', 'по отчество'),
      },
      {
        title: 'Контакты',
        dataIndex: 'client_contacts',
        key: 'client_contacts',
        // ...this.getColumnSearchProps('client_mail', 'по почте'),
      },
      {
        title: 'Категория',
        dataIndex: 'client_category',
        key: 'client_category',
        ...this.getColumnSearchProps('client_category', 'по категории'),
      },
      {
        title: 'Фактический адрес',
        dataIndex: 'client_city',
        key: 'client_city',
        ...this.getColumnSearchProps('client_city', 'по Фактический адресу'),
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
      }
    ];


    const statusesAuto = [
      {
        value: 'Обучается',
      },
      {
        value: 'Окончил обучение',
      },
      {
        value: 'Подал заявку',
      },
    ];

    return (
      <div>
        <NavBar />
        <div className="pageContent">
          <div className="page_name">Слушатели</div>
          <Space size={10}>
            <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ client_window: true, thisNewClient: true }); this.clearNewClientData(); }}>Добавить клиента</Button>
            <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ loadusers: true }); }}>Загрузить клиентов из файла</Button>
            <Button type="default" style={{ marginBottom: "20px" }} onClick={() => { this.getClients(); openNotification('Успех', 'Данные обновлены', 3, 4.5); }}><RedoOutlined /></Button>
          </Space>

          <Modal title={"Загрузка клиентов"} open={this.state.loadusers} okText={"Добавить"} cancelText="Отмена" onOk={() => { this.uploadFile() }} onCancel={() => { this.setState({ loadusers: false }) }}>
            <form encType="multipart/form-data" >
              <label className="loadPlace" htmlFor="pc-upload-add" >
                <input style={{ display: "none" }} onChange={(e) => { this.setState({ loadInput: e, loadInputName: e.target.files[0].name }); console.log(e); }} type="file" id="pc-upload-add" multiple={false} />
                <span>{this.state.loadInputName}</span>
              </label>
            </form>
          </Modal>

          <Table columns={columns} dataSource={this.state.clients} />

          <Modal title={"Загрузка клиентов/Разбор полей"} open={this.state.parsingFieldsModal} okText={"Добавить"} cancelText="Отмена" onOk={() => { this.uploadClients(); }} onCancel={() => { this.setState({ parsingFieldsModal: false }) }}>
            <div>Сопоставьте поля из таблице с полями карточки клиента</div>
            <div className="parsingFields">
              <Divider orientation="left">Личные данные</Divider>
              <div className="flex">
                <div>Имя</div>
                <Select
                  value={this.state.uploadClientName}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientName: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Фамилия</div>
                <Select
                  value={this.state.uploadClientSurname}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientSurname: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Отчество</div>
                <Select
                  value={this.state.uploadClientPatronymic}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientPatronymic: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Почта</div>
                <Select
                  value={this.state.uploadClientMail}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientMail: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Раб. почта</div>
                <Select
                  value={this.state.uploadClientWorkMail}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientWorkMail: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Доп. почта</div>
                <Select
                  value={this.state.uploadClientAdditionalMail}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientAdditionalMail: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Телефон</div>
                <Select
                  value={this.state.uploadClientPhone}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientPhone: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Раб. телефон</div>
                <Select
                  value={this.state.uploadClientWorkPhone}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientWorkPhone: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Доп. телефон</div>
                <Select
                  value={this.state.uploadClientAdditionalPhone}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientAdditionalPhone: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Фактический адрес</div>
                <Select
                  value={this.state.uploadClientCity}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientCity: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Регион</div>
                <Select
                  value={this.state.uploadClientRegion}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientRegion: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Адрес по прописке</div>
                <Select
                  value={this.state.uploadClientCity2}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientCity2: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>День рождения</div>
                <Select
                  value={this.state.uploadClientBirthday}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientBirthday: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Пол</div>
                <Select
                  value={this.state.uploadClientPol}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientPol: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>СНИЛС</div>
                <Select
                  value={this.state.uploadClientSnils}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientSnils: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <Divider orientation="left">Данные об образовании</Divider>
              <div className="flex">
                <div>Уровень образования</div>
                <Select
                  value={this.state.uploadClientLevelDiplom}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientLevelDiplom: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>ФИО в дипломе</div>
                <Select
                  value={this.state.uploadClientFioDiplom}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientFioDiplom: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Серия диплома</div>
                <Select
                  value={this.state.uploadClientSeriesDiplom}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientSeriesDiplom: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Номер диплома</div>
                <Select
                  value={this.state.uploadClientNumberDiplom}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientNumberDiplom: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Учреждение</div>
                <Select
                  value={this.state.uploadClientInstitution}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientInstitution: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <Divider orientation="left">Данные по обучению</Divider>
              <div className="flex">
                <div>Статус</div>
                <Select
                  value={this.state.uploadClientStatus}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientStatus: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Серия удостоверения</div>
                <Select
                  value={this.state.uploadClientDocSeries}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientDocSeries: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Номер удостоверения</div>
                <Select
                  value={this.state.uploadClientDocNumber}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientDocNumber: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Дата удостоверения</div>
                <Select
                  value={this.state.uploadClientDocDate}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientDocDate: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Регистрационный номер</div>
                <Select
                  value={this.state.uploadClientRegistrationNumber}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientRegistrationNumber: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>

              <div className="flex">
                <div>Категория</div>
                <Select
                  value={this.state.uploadClientCategory}
                  style={{
                    width: "calc(100% - 92px)",
                  }}
                  onChange={(value) => { this.setState({ uploadClientCategory: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Ссылка на платформу фед. оператора</div>
                <Select
                  value={this.state.uploadClientFedLink}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientFedLink: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Номер приказа на зачисление</div>
                <Select
                  value={this.state.uploadClientNumberTransferOrder}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientNumberTransferOrder: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Дата регистрации приказа на зачисление</div>
                <Select
                  value={this.state.uploadClientDateTransferOrder}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientDateTransferOrder: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Номер приказа на отчисление</div>
                <Select
                  value={this.state.uploadClientNumberWithdrawalOrder}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientNumberWithdrawalOrder: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Дата регистрации приказа на отчисление</div>
                <Select
                  value={this.state.uploadClientDateWithdrawalOrder}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientDateWithdrawalOrder: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <div className="flex">
                <div>Пароль</div>
                <Select
                  value={this.state.uploadClientPassword}
                  style={{
                    width: 200,
                  }}
                  onChange={(value) => { this.setState({ uploadClientPassword: value }) }}
                >
                  {
                    this.state.uploadFileJsonFields.length !== 0 ?
                      this.state.uploadFileJsonFields.map((elem, index) => {
                        return (
                          <Option key={elem} value={elem}>
                            {elem.value}
                          </Option>
                        )
                      }) : "Список пустой :("
                  }
                </Select>
              </div>
              <Divider orientation="left">Загрузка в группу</Divider>
              <div className="flex">
                <div>Определить в группу?</div>
                <Checkbox checked={this.state.uploadClientEnableGroup} onChange={(e) => { this.setState({ uploadClientEnableGroup: e.target.checked }) }}>{this.state.uploadClientEnableGroup == true ? "Да" : "Нет"}</Checkbox>
              </div>

              {
                this.state.uploadClientEnableGroup == true ?
                  <div className="flex">
                    <div>Выберите группу</div>
                    <Select
                      value={this.state.uploadClientSelectedGroup}
                      style={{
                        width: 200,
                      }}
                      onChange={(value) => { this.setState({ uploadClientSelectedGroup: value }) }}
                    >
                      {
                        this.state.uploadClientGroups.length !== 0 ?
                          this.state.uploadClientGroups.map((elem, index) => {
                            return (
                              <Option key={index} value={elem.scg_id}>
                                {elem.scg_name}
                              </Option>
                            )
                          }) : "Список пустой :("
                      }
                    </Select>
                  </div>
                  :
                  ""
              }
            </div>


          </Modal>

          <Drawer
            title={this.state.thisNewClient === true ? "Новый клиент " + this.state.newClientSurname + " " + this.state.newClientName + " " + this.state.newClientPatronymic : this.state.newClientSurname + " " + this.state.newClientName + " " + this.state.newClientPatronymic}
            placement="bottom"
            height={"100vh"}
            onClose={() => { this.closeDrawer(); }}
            open={this.state.client_window}
            extra={
              <Space>
                {
                  this.state.thisNewClient === true && this.state.inputChanged === true && this.state.newClientSurname !== "" && this.state.newClientName !== "" && this.state.newClientPhone !== "" && this.state.newClientMail !== "" ?
                    <Button type="primary" onClick={() => { this.createClient(); }}>
                      Добавить клиента
                    </Button>
                    :
                    ""
                }

                {
                  this.state.thisNewClient === false && this.state.inputChanged === true ?
                    <Button type="primary" onClick={() => { this.patchClient(); }}>
                      Сохранить изменения
                    </Button>
                    :
                    ""
                }

                <Button onClick={() => { this.closeDrawer() }}>Закрыть</Button>
              </Space>
            }
          >
            {
              this.state.thisNewClient === true ?
                <Alert
                  message={"ВНИМАНИЕ! Для создания клиента и дальнейшей его идентификации необходимо заполнить поля: имя, фамилия, телефон и почта. Надеемся на понимание, это сделано для вашего же удобства."}
                  type="warning"
                  banner
                />
                : ""
            }
            <Row gutter={[16, 16]}>
              <Col flex={1}>
                <Divider orientation="left">Личные данные</Divider>
                <Space direction="vertical">
                  <Input addonBefore="Имя:" name="newClientName" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientName} />
                  <Input addonBefore="Фамилия:" name="newClientSurname" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientSurname} />
                  <Input addonBefore="Отчество:" name="newClientPatronymic" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientPatronymic} />
                  <Input addonBefore="Почта:" name="newClientMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientMail} placeholder="xxx@xxx.xx" />
                  <Input addonBefore="Раб. почта:" name="newClientWorkMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientWorkMail} placeholder="xxx@xxx.xx" />
                  <Input addonBefore="Доп. почта:" name="newClientAdditionalMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientAdditionalMail} placeholder="xxx@xxx.xx" />
                  <Input addonBefore="Телефон:" name="newClientPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientPhone} placeholder="+7xxxxxxxxxx" />
                  <Input addonBefore="Раб. телефон:" name="newClientWorkPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientWorkPhone} placeholder="+7xxxxxxxxxx" />
                  <Input addonBefore="Доп. телефон:" name="newClientAdditionalPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientAdditionalPhone} placeholder="+7xxxxxxxxxx" />
                  <Input addonBefore="Фактический адрес:" name="newClientCity" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientCity} />
                  <Input addonBefore="Регион:" name="newClientRegion" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientRegion} />
                  <Input addonBefore="Адрес прописки:" name="newClientCity2" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientCity2} />
                  <Input addonBefore="День рождения:" name="newClientBirthday" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientBirthday} />
                  <Input addonBefore="Пол:" name="newClientPol" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientPol} />
                  <Input addonBefore="СНИЛС:" name="newClientSnils" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientSnils} placeholder="xxx-xxx-xxx xx" />
                </Space>
              </Col>
              <Col flex={1}>
                <Divider orientation="left">Данные об образовании</Divider>

                <Space direction="vertical">
                  <Input addonBefore="Уровень образования:" name="newClientLevelDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientLevelDiplom} />
                  <Input addonBefore="ФИО в дипломе:" name="newClientFioDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientFioDiplom} />
                  <Input addonBefore="Серия диплома:" name="newClientSeriesDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientSeriesDiplom} />
                  <Input addonBefore="Номер диплома:" name="newClientNumberDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientNumberDiplom} />
                  <Input addonBefore="Учреждение:" name="newClientInstitution" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientInstitution} />
                </Space>
              </Col>
              <Col flex={1}>
                <Divider orientation="left">Данные по обучению</Divider>
                <Space direction="vertical">
                  <Input.Group compact>
                    <span className="input_line_name">Статус:</span>
                    <AutoComplete addonBefore="Статус:" style={{ width: 'calc(100% - 65px)' }} value={this.state.newClientStatus} options={statusesAuto} filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} name="newClientStatus" onChange={(val) => { this.setState({ newClientStatus: val }) }} />
                  </Input.Group>
                  <Input addonBefore="Серия удостоверения:" value={this.state.newClientDocSeries} name="newClientDocSeries" onChange={this.ChangeInputValue.bind(this)} />
                  <Input addonBefore="Номер удостоверения:" value={this.state.newClientDocNumber} name="newClientDocNumber" onChange={this.ChangeInputValue.bind(this)} />
                  <Input addonBefore="Дата удостоверения:" value={this.state.newClientDocDate} name="newClientDocDate" onChange={this.ChangeInputValue.bind(this)} />
                  <Input addonBefore="Регистрационный номер:" value={this.state.newClientRegistrationNumber} name="newClientRegistrationNumber" onChange={this.ChangeInputValue.bind(this)} />
                  {/* <Input addonBefore="Группа:" name="newClientGroupId" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientGroupId} /> */}
                  {/* <Input addonBefore="Курс:" name="newClientCourseId" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientCourseId} /> */}
                  {/* <Input addonBefore="Проект:" name="newClientProjectId" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientProjectId} /> */}
                  {/* <Input addonBefore="Цена:" value={this.state.newClientPrice} name="newClientPrice" onChange={this.ChangeInputValue.bind(this)} /> */}
                  <Input addonBefore="Категория:" name="newClientCategory" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientCategory} />
                  {/* <Input addonBefore="Платформа обучения:" name="newClientPlatformId" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientPlatformId} /> */}

                  <Space direction="horizontal">
                    <Input addonBefore="Профиль в платформе фед. оператора:" name="newClientFedLink" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientFedLink} />
                    <Button
                      style={{
                        width: 80,
                      }}
                      onClick={() => { window.open(this.state.newClientFedLink, '_blank').focus(); }}
                    >
                      <EyeOutlined />
                    </Button>
                  </Space>
                  {/* <Input addonBefore="Прикрепленный менеджер:" name="newClientManager" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientManager} /> */}
                  <Input addonBefore="Номер приказа на зачисление:" name="newClientNumberTransferOrder" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientNumberTransferOrder} />
                  <Input addonBefore="Дата регистрации приказа на зачисление:" name="newClientDateTransferOrder" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientDateTransferOrder} />
                  <Input addonBefore="Номер приказа на отчисление:" name="newClientNumberWithdrawalOrder" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientNumberWithdrawalOrder} />
                  <Input addonBefore="Дата регистрации приказа на отчисление:" name="newClientDateWithdrawalOrder" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientDateWithdrawalOrder} />
                </Space>
              </Col>
            </Row>
            <Modal title={"Изменение пароля клиента"} open={this.state.clientPassEdit} okText={"Изменить"} cancelText="Отмена" onOk={() => { this.editClientPassword() }} onCancel={() => { this.setState({ clientPassEdit: false }) }}>
              <Input addonBefore="Новый пароль:" value={this.state.newClientPassword} name="newClientPassword" onChange={(e) => { this.setState({ newClientPassword: e.target.value }) }} />
            </Modal>
            <Row gutter={[16, 16]}>
              <Col flex={1}>
                <Divider orientation="left">Данные авторизации</Divider>
                {
                  this.state.thisNewClient === true ?
                    <Input addonBefore="Пароль:" value={this.state.newClientPassword} name="newClientPassword" onChange={(e) => { this.setState({ newClientPassword: e.target.value }) }} />
                    :
                    <Space direction="vertical">
                      <Input addonBefore="Логин:" value={this.state.newClientLogin} name="newClientLogin" onChange={this.ChangeInputValue.bind(this)} />
                      <Button type="primary" onClick={() => { this.setState({ clientPassEdit: true }) }}>Изменить пароль</Button>
                    </Space>
                }
              </Col>
            </Row>

          </Drawer>
        </div>
      </div>
    );


  }

  async componentDidMount() {
    this.getClients();
  }

}